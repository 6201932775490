// import type { ClassBreaks } from './classBreaks'
import { ROAD_TYPES } from '@cd/screen-data'
import type { Reactive } from 'vue'
import type { GeometryType } from '@/types/index.types'
import type { CellsData } from '@/types/maps.types'

type AvailableFilters<T = string> = {
  name: T
  label: string
}[]

enum LayerTypes {
  PATHS = 'roads',
  AREAS = 'areas',
  NETWORK = 'network',
}

type LayerType = `${LayerTypes}`

type OSMRoadType = string
type CityRoadType = string
export type RoadType = OSMRoadType | CityRoadType

export interface RoadLines {
  roadType: RoadType
  lines: [[number, number][]]
}

export type Network = RoadLines[]
export type NetorkLineID = `${number}_${number}`
export type NetworkData = Map<NetorkLineID, string> | Map<NetorkLineID, number>

type ZoomBounds = [number, number]

interface MetricDefinition {
  metric: string
  breaks: number[]
  labels?: string[]
  filePath?: string // full file name
}

interface FileDefinitionBase {
  name: string
  type?: 'geometryType' | 'networkType'
  filePath: string
  metrics: MetricDefinition[]
}

interface FileDefinitionVariant extends FileDefinitionBase {
  variant: string
}

interface FileDefinitionWithZoomBounds extends FileDefinitionBase {
  zoomBounds: ZoomBounds
}

interface FileDefinitionWithZoomBoundsVariant extends FileDefinitionVariant, FileDefinitionWithZoomBounds { }

type FileDefinition = FileDefinitionBase | FileDefinitionVariant | FileDefinitionWithZoomBounds | FileDefinitionWithZoomBoundsVariant

interface LayerFileDefinition<T extends LayerType> {
  layer: T
  file: FileDefinitionBase
}

interface LayerFilesDefinition<T extends LayerType> {
  layer: T
  files: FileDefinitionBase[] | FileDefinitionVariant[] | FileDefinitionWithZoomBounds[] | FileDefinitionWithZoomBoundsVariant[]
}

type LayerDefinition<T extends LayerType = LayerType> = LayerFileDefinition<T> | LayerFilesDefinition<T>
type ConfigFile<T extends LayerType = LayerType> = LayerDefinition<T>[]

interface RoadPoiBaseProperties {
  picture: string
  name: string
}

interface RoadFeatureProperties extends RoadPoiBaseProperties {
  mean: number
}

interface PoiFeatureProperties extends RoadPoiBaseProperties {
  path: string | null
}

const FILE_PATH_BASE = 'https://screen-data-staging.s3.nl-ams.scw.cloud/prod'

function forEachConfigFiles(config: ConfigFile, itterator: ({
  layer,
  file,
}: {
  layer: LayerType
  file: FileDefinition
}) => void | false): void {
  let stopClaim = false

  for (const n of config) {
    if ('files' in n) {
      if (stopClaim) {
        break
      }

      for (const f of n.files) {
        if (itterator({
          layer: n.layer,
          file: f,
        }) === false) {
          stopClaim = true
          break
        }
      }
    } else {
      if (itterator({
        layer: n.layer,
        file: n.file,
      }) === false) {
        break
      }
    }
  }
}

// return the first config file that matches the itterator
function findFileInConfig(config: ConfigFile, itterator: (file: FileDefinition) => boolean): FileDefinition | null {
  let file = null

  forEachConfigFiles(config, ({ file: f }) => {
    if (itterator(f)) {
      file = f
      return false
    }
  })

  return file
}

function inZoomBounds(f: FileDefinition, zoom: number): boolean {
  if ('zoomBounds' in f && f.zoomBounds) {
    const [min, max] = f.zoomBounds
    return zoom >= min && zoom <= max
  }

  return false
}

export const useCyclability = createSharedComposable((citySlug: Ref<string | null>, mapOptions?: Reactive<{
  zoom: number
  center?: [number, number]
  bounds?: [[number, number], [number, number]]
}>) => {
  const { t } = useI18n()
  const { translateFromData } = useLabelTranslation()

  const loading = ref(true)
  const configFile = shallowRef<ConfigFile | null>()

  const {
    geometryType,
    areasMetric,
    networkType,
    networkMetric,
  } = useReactiveParams({
    geometryType: { name: 'geometry', defaultValue: null },
    areasMetric: { name: 'geoMetric' },
    networkType: { name: 'network' },
    networkMetric: { name: 'networkMetric' },
  })

  const availableGeometries = shallowRef<AvailableFilters<GeometryType>>([])
  const availableAreasMetrics = shallowRef<AvailableFilters>()
  const availableNetworks = shallowRef<AvailableFilters>([])
  const availableNetworkMetrics = shallowRef<AvailableFilters>()

  const areasConfig = shallowRef<ConfigFile<LayerTypes.AREAS>>([])
  const networksConfig = shallowRef<ConfigFile<LayerTypes.NETWORK>>([])

  const areaActiveFile = shallowRef<FileDefinitionWithZoomBounds | null>()
  const networkActiveFile = shallowRef<FileDefinitionWithZoomBoundsVariant | null>()

  async function getConfigFile() {
    loading.value = true

    try {
      const results = await fetch(urlToFetch('/metrics.json'))
      configFile.value = await results.json() as ConfigFile<LayerType>
      prepareCityConfig(configFile.value)
    } catch (e) {
      console.error(e)
      reset()
    }

    loading.value = false
  }

  function reset() {
    availableGeometries.value = []
    availableNetworks.value = []
    areasConfig.value = []
    networksConfig.value = []
    availableAreasMetrics.value = undefined
    availableNetworkMetrics.value = undefined
  }

  async function prepareCityConfig(config: ConfigFile<LayerType>) {
    reset()

    const availableGeometriesTmp: GeometryType[] = []
    const availableNetworksTmp: string[] = []

    const areas = config.filter((c): c is LayerDefinition<LayerTypes.AREAS> => c.layer === LayerTypes.AREAS)
    const networks = config.filter((c): c is LayerDefinition<LayerTypes.NETWORK> => c.layer === LayerTypes.NETWORK)

    forEachConfigFiles(areas, ({ file }) => {
      if (file.type === 'geometryType' && !availableGeometriesTmp.includes(file.name as GeometryType)) {
        availableGeometriesTmp.push(file.name as GeometryType)
      }
    })

    forEachConfigFiles(networks, ({ file }) => {
      if (file.type === 'networkType' && !availableNetworksTmp.includes(file.name)) {
        availableNetworksTmp.push(file.name)
      }
    })

    // save filtered configs
    areasConfig.value = areas
    networksConfig.value = networks

    // TODO: adapt default values with last selected values
    // setup selected network value
    if (!networkType.value || !availableNetworksTmp.includes(networkType.value)) {
      networkType.value = availableNetworksTmp[0]
    }

    // setup selected geometry value
    const lastSelectedGeometry = geometryType.value

    if (
      lastSelectedGeometry === undefined
      || (lastSelectedGeometry && !availableGeometriesTmp.includes(lastSelectedGeometry as GeometryType))
    ) {
      geometryType.value = availableGeometriesTmp[0]
    }

    // setup available filters
    availableGeometries.value = availableGeometriesTmp.map(n => ({
      name: n as GeometryType,
      label: translateFromData('geometry', n),
    }))

    // null value is for the auto selection and added directly to the list in the selector options
    availableNetworks.value = availableNetworksTmp.map(n => ({
      name: n,
      label: t(n),
    }))

    updateActiveFile({
      geometryType: geometryType.value,
      networkType: networkType.value,
    })
  }

  function checkFileNameAndZoomBounds<T = string | null>(f: FileDefinition, name: T) {
    if (
      (name === null && !('variant' in f))
      || ('variant' in f && f.name === name)
    ) {
      return inZoomBounds(f, mapOptions?.zoom || 0)
    }

    return f.name === name
  }

  function updateActiveFile({
    geometryType,
    networkType,
  }: {
    geometryType?: GeometryType | string | null
    networkType?: string | null
  }) {
    if (geometryType !== undefined) {
      const ac = findFileInConfig(areasConfig.value, f => checkFileNameAndZoomBounds(f, geometryType))

      if (ac) {
        areaActiveFile.value = ac as FileDefinitionWithZoomBounds
        availableAreasMetrics.value = ac?.metrics.map(m => ({
          name: m.metric,
          label: t(m.metric),
        })) || []
      }
    }

    if (networkType !== undefined) {
      const nc = findFileInConfig(networksConfig.value, f => checkFileNameAndZoomBounds(f, networkType))

      if (nc) {
        networkActiveFile.value = nc as FileDefinitionWithZoomBoundsVariant
        availableNetworkMetrics.value = nc.metrics.map(m => ({
          name: m.metric,
          label: t(m.metric),
        })) || []
      }
    }
  }

  function urlToFetch(path: string) {
    return `${FILE_PATH_BASE}/${citySlug.value}/${path.indexOf('/') === 0 ? path.slice(1) : path}`
  }

  whenever(citySlug, getConfigFile, { immediate: true })
  whenever(networkType, networkType => updateActiveFile({ networkType }))
  whenever(geometryType, geometryType => updateActiveFile({ geometryType }))
  whenever(() => mapOptions, () => {
    updateActiveFile({
      geometryType: geometryType.value,
      networkType: networkType.value,
    })
  }, { deep: true })

  return {
    loading,
    geometryType,
    networkType,
    networkMetric,
    areasMetric,
    availableGeometries,
    availableNetworks,
    areasConfig,
    networksConfig,
    areaActiveFile,
    networkActiveFile,
    availableAreasMetrics,
    availableNetworkMetrics,
    urlToFetch,
  }
})

export const useCyclabilityAreas = createSharedComposable((citySlug: Ref<string | null>) => {
  const { areaActiveFile, urlToFetch, areasMetric, availableAreasMetrics } = useCyclability(citySlug)

  const loading = ref(0)
  const areas = shallowRef<string[]>([])
  const areasData = shallowRef<CellsData>(new Map())

  const metricActiveFile = computed(() => {
    return areaActiveFile.value?.metrics.find(m => m.metric === areasMetric.value)
  })

  const areasBreaks = computed(() => {
    const breaks = metricActiveFile.value?.breaks || []
    return makeClassBreaks(breaks)
  })

  async function getCyclabilityAreas() {
    loading.value++

    const url = urlToFetch(areaActiveFile.value?.filePath || '')
    const data = await fetchParquet(url)
    const key = Object.keys(data[0])[0]

    areas.value = data.map(record => record[key])

    loading.value--
  }

  async function getCyclabilityAreasData() {
    loading.value++

    try {
      const url = urlToFetch(metricActiveFile.value?.filePath || '')
      const raw = await fetchParquet(url)
      const key = Object.keys(raw[0])[0]
      const data = raw.map(record => record[key])
      const map = new Map()

      for (let i = 0; i < areas.value.length; i++) {
        // @ts-expect-error because the type definition is wrong
        map.set(areas.value[i].toString(16), data[i])
      }

      areasData.value = map
    } catch {
      areasData.value = new Map()
    }

    loading.value--
  }

  whenever(areaActiveFile, getCyclabilityAreas, { immediate: true })
  whenever(metricActiveFile, getCyclabilityAreasData, { immediate: true })
  whenever(availableAreasMetrics, (availableAreasMetrics) => {
    const lastValue = areasMetric.value

    if (lastValue && availableAreasMetrics.map(m => m.name).includes(lastValue as string)) {
      areasMetric.value = lastValue
    } else {
      areasMetric.value = availableAreasMetrics[0]?.name || undefined
    }
  }, { immediate: true })

  return {
    loading: computed(() => loading.value > 0),
    areasBreaks,
    areasData,
    getCyclabilityAreas,
    getCyclabilityAreasData,
  }
})

export const useCyclabilityNetwork = createSharedComposable((citySlug: Ref<string | null>) => {
  const { networkActiveFile, urlToFetch, networkMetric, availableNetworkMetrics } = useCyclability(citySlug)

  const loading = ref(0)
  const network = shallowRef<RoadLines[]>([])
  const networkData = shallowRef<NetworkData>(new Map())

  const metricActiveFile = computed(() => {
    return networkActiveFile.value?.metrics.find(m => m.metric === networkMetric.value)
  })

  const networkBreaks = computed(() => {
    const f = metricActiveFile.value

    if (!f) {
      return
    }

    const breaks = metricActiveFile.value?.breaks || []
    return makeClassBreaks(breaks)
  })

  async function getCyclabilityNetwork() {
    loading.value++
    const url = urlToFetch(networkActiveFile.value?.filePath || '')

    try {
      const result = await fetchNetwork(url)

      network.value = result?.map(({ roadType, lines }) => {
        return {
          roadType,
          lines: lines || [],
          index: ROAD_TYPES.findIndex((d) => {
            return d === roadType
          }),
        }
      }) || []
    } catch {
      network.value = []
    }

    loading.value--
  }

  async function getCyclabilityNetworkData() {
    loading.value++

    try {
      const url = urlToFetch(metricActiveFile.value?.filePath || '')
      const raw = await fetchParquet(url)
      const key = Object.keys(raw[0])[0]
      const data = raw.map(record => record[key])
      const map = new Map()

      for (let i = 0; i < network.value.length; i++) {
        const { lines } = network.value[i]

        for (let y = 0; y < lines.length; y++) {
          map.set(`${i}_${y}`, data[y])
        }
      }

      networkData.value = map
    } catch (error) {
      console.error('error when fetching network data: ', error)
      networkData.value = new Map()
    }

    loading.value--
  }

  whenever(networkActiveFile, getCyclabilityNetwork, { immediate: true })
  whenever(metricActiveFile, getCyclabilityNetworkData, { immediate: true })
  whenever(availableNetworkMetrics, (availableNetworkMetrics) => {
    const lastValue = networkMetric.value

    if (availableNetworkMetrics.map(m => m.name).includes(lastValue as string)) {
      networkMetric.value = lastValue
    } else {
      networkMetric.value = availableNetworkMetrics[0]?.name || undefined
    }
  }, { immediate: true })

  return {
    loading: computed(() => loading.value > 0),
    network,
    networkData,
    networkBreaks,
    getCyclabilityNetwork,
    getCyclabilityNetworkData,
  }
})

export const useCyclabilityRoads = createSharedComposable((citySlug: Ref<string | null>) => {
  const { urlToFetch } = useCyclability(citySlug)

  const poisGeojson = ref(newFeatureCollection<GeoJSON.Point, PoiFeatureProperties>())
  const roadsGeojson = ref(newFeatureCollection<GeoJSON.LineString, RoadFeatureProperties>())

  // const roadsBreaks = computed(() => {
  //   return makeClassBreaks([0, 100, 200, 300, 400, 500])
  // })

  const { isFetching, error, onFetchResponse } = useFetch<GeoJSON.FeatureCollection<GeoJSON.Point>>(urlToFetch('/road.geojson'))

  onFetchResponse(async (response) => {
    const geojson = await response.json()
    const tmpPoisGeojson = newFeatureCollection<GeoJSON.Point, PoiFeatureProperties>()
    const tmpRoadsGeojson = newFeatureCollection<GeoJSON.LineString, RoadFeatureProperties>()

    for (const feature of geojson.features) {
      if (!feature.properties) {
        continue
      }

      if (feature.geometry.type === 'Point') {
        feature.properties = {
          ...feature.properties,
          name: feature.properties.picture,
          path: feature.properties.picture ? `https://screen-data.s3.fr-par.scw.cloud/barcelona/pictures/${feature.properties.picture}.jpg` : null, // urlToFetch(`/pictures/${feature.properties.picture}`),
        }

        tmpPoisGeojson.features.push(feature)
      } else {
        feature.properties = {
          ...feature.properties,
          mean: Number(feature.properties.score),
          name: feature.properties.picture,
        }

        tmpRoadsGeojson.features.push(feature)
      }
    }

    poisGeojson.value = tmpPoisGeojson
    roadsGeojson.value = tmpRoadsGeojson
  })

  watch(error, (error) => {
    if (error) {
      console.error(error)
    }
  })

  return {
    loading: isFetching,
    roadsGeojson,
    poisGeojson,
  }
})
