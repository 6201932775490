<script setup lang="ts">
import { cellsToMultiPolygon, polygonToCells } from 'h3-js'
import bboxPolygon from '@turf/bbox-polygon'
import { MAP_DEFAULT_COLOR, useMap } from '@/components/MapLibre/maplibreUtils'
import type { CellsData } from '@/types/maps.types'
import type { CellsType } from '@/types/index.types'
import { GeometriesTypes } from '@/types/index.types'
import type { ClassBreaks } from '@/composables/classBreaks'

interface MapCellsGridProps {
  layerId?: string
  data: CellsData
  classBreaks: ClassBreaks
  cellsType?: CellsType
  selected?: string[]
  paintOptions?: {
    'fill-color': string
    'fill-opacity': number
  }
}

defineOptions({
  name: 'CellsGrid',
})

const props = withDefaults(defineProps<MapCellsGridProps>(), {
  layerId: 'cells-grid',
  data: () => new Map(),
  selected: () => [],
  paintOptions: () => ({
    'fill-color': MAP_DEFAULT_COLOR,
    'fill-opacity': 0.5,
  }),
  cellsType: GeometriesTypes.H3_10,
})

const emit = defineEmits(['ready'])

const levelByType = Object.values(GeometriesTypes).filter(t => isCellsType(t)).reduce((acc, type: CellsType) => {
  acc[type] = Number.parseInt(type.split('_')[1])
  return acc
}, {} as Record<CellsType, number>)

const { data, classBreaks } = toRefs(props)

const prepare = ref(false)
const working = ref(false)
const features = shallowRef<GeoJSON.Feature[]>([])
const classedCells = shallowRef<CellsData[]>([])
const grid = ref<GeoJSON.Feature[]>([])

const colorBreaks = useExpressionColorFromBreaks(classBreaks)
const { map } = useMap()

const paintExpression = computed(() => {
  return {
    ...props.paintOptions,
    'fill-color': colorBreaks.value,
  }
})

const geojson = computed(() => {
  return newFeatureCollection(features.value || [])
})

const gridGeojson = computed(() => {
  return newFeatureCollection(grid.value || [])
})

function getBoundsPolygon(): GeoJSON.Feature<GeoJSON.Polygon> {
  if (!map.value) {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    }
  }

  // TODO: retrieve the cell length from the zoom level
  const KM_PER_DEGREE = 111
  const CELL_LENGTH_11 = 0.05
  const CELL_RADIUS = 1 / (KM_PER_DEGREE / CELL_LENGTH_11)

  return bboxPolygon(
    map.value
      .getBounds()
      .toArray()
      .map((pt, i) => pt.map(v => i ? v + CELL_RADIUS : v - CELL_RADIUS).reverse())
      .flat() as [number, number, number, number],
  )
}

const updateGrid = useDebounceFn(() => {
  if (working.value || !map.value) {
    return
  }

  working.value = true

  const bounds = getBoundsPolygon()
  grid.value = [bounds]

  const visibleCells = new Set(polygonToCells(bounds.geometry.coordinates[0], levelByType[props.cellsType]))
  const classes = unref(classedCells)
  const filteredClassesCells: string[][] = []

  for (let i = 0, il = classes.length; i < il; i++) {
    const cells = new Set(classes[i].keys())

    if (cells.size === 0) {
      continue
    }

    filteredClassesCells[i] = []

    visibleCells.forEach((cell) => {
      if (cells.has(cell)) {
        filteredClassesCells[i].push(cell)
        visibleCells.delete(cell)
      }
    })
  }

  nextTick(async () => {
    await setFeaturesFromClasses(filteredClassesCells)

    working.value = false
  })
}, 300)

async function setFeaturesFromClasses(classes: string[][]) {
  if (!classes || classes.length === 0) {
    features.value = []
    return
  }

  const cbs = unref(classBreaks)
  let classesIndex = 0

  features.value = await asyncExecByFrame<GeoJSON.Feature[]>(classes, async (classe, acc) => {
    if (classe) {
      acc.push({
        type: 'Feature',
        properties: {
          name: `break_${classesIndex}`,
          mean: cbs[classesIndex]?.from,
        },
        geometry: {
          type: 'MultiPolygon',
          coordinates: cellsToMultiPolygon(classe as string[], true),
        },
      })

      features.value.push(...acc)
      await nextTick()
    }

    classesIndex++
    return acc
  }, [])
}

watchDebounced(data, (cellsData) => {
  prepare.value = true

  // Prepare filtered and classed data tables
  const cbs = unref(classBreaks)
  const classedCellsTmp: CellsData[] = []
  for (let c = 0; c < cbs.length; c++) {
    classedCellsTmp[c] = new Map()
  }

  cellsData.forEach((mean, cell) => {
    // if no data, skip
    if (mean === undefined) {
      return
    }

    // retrieve the class of the data
    const indexOfClass = cbs.findIndex((cb) => {
      return cb.upperBound >= mean
    })

    if (indexOfClass >= 0) {
      classedCellsTmp[indexOfClass].set(cell, mean)
    }
  })

  classedCells.value = classedCellsTmp
  prepare.value = false

  nextTick(() => {
    updateGrid()
  })
}, { debounce: 300, immediate: true })

onMounted(() => {
  if (map.value) {
    map.value.on('moveend', updateGrid)
    map.value.on('zoomend', updateGrid)
  }
})

onUnmounted(() => {
  if (map.value) {
    map.value.off('moveend', updateGrid)
    map.value.off('zoomend', updateGrid)
  }
})
</script>

<template>
  <MapLibreSourceGeojson
    v-if="features.length > 0"
    :id="layerId"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
    :layer-props="{
      type: 'fill',
      paint: paintExpression,
    }"
    :selected-features="selected || []"
    @ready="() => emit('ready')"
  />

  <MapLibreSourceGeojson
    v-if="grid && grid.length > 0"
    :id="`${layerId}-grid`"
    :layer-props="{
      type: 'fill',
      paint: {
        'fill-color': MAP_DEFAULT_COLOR,
        'fill-opacity': 0.2,
        'fill-outline-color': '#fff',
      },
    }"
    :data="gridGeojson"
    @ready="() => emit('ready')"
  />

  <MapLibreControl
    v-if="working || prepare"
    position="top-right"
  >
    <DLoader />
  </MapLibreControl>
</template>
