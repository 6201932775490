<script setup lang="ts">
import type { ClassBreaks } from '@/composables/classBreaks'
import type { Network, NetworkData } from '@/composables/cyclability'
import type { GeometryType } from '@/types/index.types'
import type { CellsData } from '@/types/maps.types'

defineOptions({
  name: 'CyclabilityMapBikeability',
})

const { t } = useI18n()
const navigationStore = useNavigationStore()
const cityStore = useCityStore()
const { slug, city, isLoading } = storeToRefs(cityStore)
const mapOptions = reactive<{
  zoom: number
  center?: [number, number]
  bounds?: [[number, number], [number, number]]
}>({
  zoom: 7, // TODO real default
  center: undefined, // TODO real default
  bounds: undefined, // TODO real default
})

const { availableGeometries, availableAreasMetrics, areaActiveFile, geometryType, areasMetric, availableNetworks, availableNetworkMetrics, networkMetric, networkType } = useCyclability(slug, mapOptions)
const { areasData, areasBreaks, loading: areasLoading } = useCyclabilityAreas(slug)
const { network, networkData, networkBreaks, loading: networkLoading } = useCyclabilityNetwork(slug)

const data = computed((): {
  areas: { data: CellsData, classbreaks: ClassBreaks }
  network: { roads: Network, data: NetworkData, classbreaks?: ClassBreaks }
} => {
  return {
    areas: {
      data: unref(areasData),
      classbreaks: unref(areasBreaks),
    },
    network: {
      roads: unref(network),
      data: unref(networkData),
      classbreaks: unref(networkBreaks),
    },
  }
})

watch(city, (city, lastCity) => {
  if (city && city.name !== lastCity?.name) {
    mapOptions.center = [city.center.lng, city.center.lat]
    mapOptions.zoom = city.default_zoom
  }
}, { immediate: true })
</script>

<template>
  <MapContainer>
    <Teleport
      v-if="navigationStore.filtersIsMounted"
      to="#teleport-sidebar"
    >
      <DSelectPicker
        v-if="availableAreasMetrics"
        v-model="areasMetric"
        icon="hexagones"
        :options="availableAreasMetrics.map(t => ({ value: t.name, label: t.label }))"
        :label="t('Metric')"
        :is-loading="isLoading"
      />

      <DSelectPicker
        v-model="geometryType"
        icon="hexagones"
        :options="[
          {
            label: t('Auto (zoom level)'),
            value: null,
            default: true,
          },
          ...availableGeometries.map(({ name, label }) => ({ value: name, label })),
        ]"
        :label="t('Statistical area')"
        :is-loading="isLoading"
        :default-text="`${areaActiveFile?.name || geometryType} (auto)`"
      />

      <DSelectPicker
        v-if="availableNetworkMetrics"
        v-model="networkMetric"
        icon="hexagones"
        :options="[
          {
            label: t('Road type'),
            value: null,
          },
          ...availableNetworkMetrics.map(({ name, label }) => ({ value: name, label })),
        ]"
        :label="t('Metric')"
        :is-loading="isLoading"
      />

      <DSelectPicker
        v-model="networkType"
        icon="hexagones"
        :label="t('Network')"
        :is-loading="isLoading"
        :options="availableNetworks.map(({ name, label }) => ({ value: name, label }))"
      />
    </Teleport>

    <CyclabilityMap
      :data="data"
      :is-loading="isLoading || areasLoading || networkLoading"
      v-bind="mapOptions"
      :geometry-type="areaActiveFile?.name as GeometryType || geometryType"
      :legend-title="t('City Bikeability')"
      @on-zoom="(zoom) => {
        mapOptions.zoom = Math.floor(zoom)
      }"
    />
  </MapContainer>
</template>
