<script setup lang="ts">
import Spiderfy from '@nazka/map-gl-js-spiderfy'

import type { GeoJSONFeature, Map, MapLayerMouseEvent, MapMouseEvent } from 'maplibre-gl'
import { useSource } from './layerSource'

defineOptions({
  name: 'MapLibreSourceGeojson',
})

// Layer properties https://maplibre.org/maplibre-style-spec/layers/
const props = withDefaults(defineProps<MapLibreSourceGeojsonProps>(), {
  geojsonOptions: () => ({
    generateId: true,
  }),
  clusterOptions: () => ({
    cluster: false,
  }),
  spiderify: false,
  selectedFeatures: null,
})

const emit = defineEmits([
  'ready',
  'click',
  'mousemove',
  'highlight',
  'unhighlight',
])

type Layer = Record<string, any>

interface MapLibreSourceGeojsonProps {
  id: string
  data: string | GeoJSON.FeatureCollection
  layerProps?: Layer | Layer[]
  geojsonOptions?: {
    promoteId?: string
    generateId?: boolean
  }
  clusterOptions?: {
    cluster: boolean
    clusterRadius?: number
    clusterMaxZoom?: number
  }
  selectedFeatures?: string[] | null
  spiderify?: string[] | boolean | null
  spiderifyOptions?: object
}

const { id, data, spiderify, spiderifyOptions, clusterOptions, selectedFeatures } = toRefs(props)

const ready = ref(false)
const highlighted = ref<string | null>(null)
const features = shallowRef<GeoJSON.Feature[]>([])
const clusters = shallowRef<object[]>([])

const map = inject<Ref<Map | null>>('map', ref(null))
const spider = ref<Spiderfy | null>(null)

const layers = computed(() => {
  const layers = props.layerProps
  return Array.isArray(layers) ? layers : [layers]
})

const { getSource } = useSource(id)

whenever(data, (data) => {
  getSource()?.setData(data)
})

whenever(clusterOptions, (clusterOptions) => {
  getSource()?.setClusterOptions(clusterOptions)
})

// Set selected features for higlighting
watch([selectedFeatures, ready], ([selectedFeatures, ready], [previusSelectedFeatures]) => {
  if (selectedFeatures && ready) {
    const mapRef = map.value
    const sourceId = id.value

    if (mapRef && sourceId) {
      previusSelectedFeatures?.forEach((featureId) => {
        mapRef.removeFeatureState(
          {
            source: sourceId,
            id: featureId,
          },
          'selected',
        )
      })

      selectedFeatures.forEach((featureId) => {
        mapRef.setFeatureState(
          {
            source: sourceId,
            id: featureId,
          },
          { selected: true },
        )
      })
    }
  }
}, { immediate: true })

onMounted(() => {
  // Set cluster options and add spiderfy
  const mapRef = map.value

  mapRef?.on('data', (e) => {
    if (clusterOptions.value) {
      const idRef = get(id)

      if ('sourceId' in e && e.sourceId === idRef && 'isSourceLoaded' in e && e.isSourceLoaded) {
        if (get(spiderify)) {
          set(
            spider,
            new Spiderfy(mapRef, {
              ...get(spiderifyOptions),
            }),
          )
        }

        if (!get(ready)) {
          mapRef.on('zoomend', updateCluster)
        }

        set(ready, true)
        updateCluster()
      }
    } else {
      set(ready, true)
    }
  })
})

onBeforeUnmount(() => {
  if (get(clusterOptions)) {
    get(map)?.off('zoomend', updateCluster)
  }

  if (get(spider)) {
    get(spider)?.unspiderfyAll()
  }
})

function updateCluster() {
  const mapRef = get(map)

  if (get(clusterOptions).cluster && mapRef) {
    const sourceId = get(id)
    const sourceFeatures = mapRef.querySourceFeatures(sourceId)
    const clustersTemp: Record<string, GeoJSONFeature> = {}
    const featuresTemp: Record<string, GeoJSONFeature> = {}

    sourceFeatures.forEach((feature) => {
      if (feature.properties.cluster) {
        clustersTemp[feature.properties.cluster_id] = feature
      } else if (feature.id != null) {
        featuresTemp[feature.id] = feature
      } else {
        console.warn('Feature without id', feature)
      }
    })

    set(clusters, Object.values(clustersTemp))
    set(features, Object.values(featuresTemp))
  }
}

function setHiglitedFeature(featureId: string) {
  set(highlighted, featureId)

  get(map)?.setFeatureState(
    {
      source: get(id),
      id: featureId,
    },
    { hover: true },
  )
}

function unsetHiglitedFeature(featureId: string) {
  set(highlighted, null)

  get(map)?.setFeatureState(
    { source: get(id), id: featureId },
    { hover: false },
  )
}

const onClick = (event: MapMouseEvent) => emit('click', event)

function onMouseMove(event: MapLayerMouseEvent) {
  if (!get(ready)) {
    return
  }

  if (event.features && event.features?.length > 0) {
    const newFeatureId = event.features[0].id as string
    const highlightedFeatureId = get(highlighted)

    if (newFeatureId !== highlightedFeatureId) {
      if (highlightedFeatureId) {
        unsetHiglitedFeature(highlightedFeatureId)
      }
      setHiglitedFeature(newFeatureId)
      emit('highlight', event)
    }
  }

  emit('mousemove', event)
}

function onMouseLeave() {
  if (!get(ready)) {
    return
  }

  const highlightedFeatureId = get(highlighted)

  if (highlightedFeatureId) {
    unsetHiglitedFeature(highlightedFeatureId)
    emit('unhighlight', highlightedFeatureId)
  }
}

function onLayerReady(layerId: string) {
  const spiderRef = get(spider)
  const spiderifyRef = get(spiderify)

  if (spiderRef) {
    if (Array.isArray(spiderifyRef)) {
      if (spiderifyRef.includes(layerId)) {
        spiderRef.applyTo(layerId)
      }
    } else {
      spiderRef.applyTo(layerId)
    }
  }

  emit('ready')
}
</script>

<template>
  <MapLibreSource
    :id="id"
    :source="{
      type: 'geojson',
      data,
      ...(geojsonOptions ? geojsonOptions : {}),
      ...(clusterOptions ? clusterOptions : {}),
    }"
  >
    <template v-if="ready">
      <MapLibreLayer
        v-for="(layer, key) in layers"
        :id="layer.id || id"
        :key="layer.id || key"
        :source="id"
        v-bind="layer"
        @click="onClick"
        @mousemove="onMouseMove"
        @mouseleave="onMouseLeave"
        @ready="() => onLayerReady(layer.id || id)"
      />
      <slot
        :get-source="() => getSource()"
        :get-layer="() => map?.getLayer(id)"
        :clusters="clusters"
        :features="features"
        :on-click="onClick"
        :on-mouse-move="onMouseMove"
        :on-mouse-leave="onMouseLeave"
      />
    </template>
  </MapLibreSource>
</template>
